import { defineStore } from 'pinia'
import { useToast } from '~/modules/ui/components/toast'

interface Conversation {
  id: string
  created_at: string
  updated_at: string
  subject: string
}

interface Message {
  id: string
  conversation_id: string
  sender_id: string
  content: string
  created_at: string
  is_edited: boolean
  is_deleted: boolean
  message_type: string
}

interface ConversationParticipant {
  id: string
  conversation_id: string
  user_id: string
  joined_at: string
  read: boolean
}

type SupabaseClientType = ReturnType<typeof useSupabaseClient>

export const useUserConversationsStore = defineStore('userConversations', () => {
  // --- state ---
  const conversations = ref<Conversation[]>([])
  const messages = ref<{ [key: string]: Message[] }>({})
  const participants = ref<{ [key: string]: ConversationParticipant[] }>({})
  const unreadMessages = ref<Message[]>([])
  const loading: Ref<boolean> = ref(false)
  const supabase = useSupabaseClient<SupabaseClientType>()
  const error: Ref<string | null> = ref(null)
  const onlineUsers = ref<Set<string>>(new Set())
  let channelInstance: ReturnType<typeof subscribeToOnlineStatus> | null = null

  // --- getters ---
  const hasUnreadMessages = computed(() => {
    return unreadMessages.value.length > 0
  })

  const getLatestMessage = (conversationId: string) => {
    if (messages.value[conversationId] && messages.value[conversationId].length > 0) {
      return messages.value[conversationId].reduce((latest, current) => {
        return new Date(latest.created_at) > new Date(current.created_at) ? latest : current
      })
    }
    return null
  }

  const isUserOnline = computed(() => (userId: string | undefined) => userId ? onlineUsers.value.has(userId) : false)

  // --- actions ---
  async function fetchConversations(): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data: { user } } = await supabase.auth.getUser()

      if (!user) { throw new Error('User not found') }
      const userId = user.id

      const { data, error: supabaseError } = await supabase
        .from('Conversation_Participants')
        .select('conversation_id')
        .eq('user_id', userId)

      if (supabaseError) { throw supabaseError }

      const conversationIds = data.map((participant: { conversation_id: string }) => participant.conversation_id)

      const { data: conversationsData, error: conversationsError } = await supabase
        .from('Conversation')
        .select('*')
        .in('id', conversationIds)
        .order('updated_at', { ascending: false })

      if (conversationsError) { throw conversationsError }

      conversations.value = conversationsData as Conversation[]
      subscribeToConversationChanges(userId)
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching user conversations:', e)
    }
    finally {
      loading.value = false
    }
  }

  async function fetchMessages(conversationId: string): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data: { user } } = await supabase.auth.getUser()

      if (!user) { throw new Error('User not found') }
      const userId = user.id
      const { data: messagesData, error: messagesError } = await supabase
        .from('Messages')
        .select('*')
        .eq('conversation_id', conversationId)
        .order('created_at', { ascending: true })

      if (messagesError) { throw messagesError }

      messages.value[conversationId] = messagesData as Message[]
      subscribeToMessageChanges(userId, conversationId)
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching messages:', e)
    }
    finally {
      loading.value = false
    }
  }

  async function fetchConversationParticipants(conversationId: string): Promise<ConversationParticipant[]> {
    loading.value = true
    error.value = null

    try {
      const { data: participantsData, error: participantsError } = await supabase
        .from('Conversation_Participants')
        .select('*')
        .eq('conversation_id', conversationId)

      if (participantsError) { throw participantsError }

      participants.value[conversationId] = participantsData as ConversationParticipant[]
      return participantsData as ConversationParticipant[]
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching conversation participants:', e)
      return []
    }
    finally {
      loading.value = false
    }
  }

  async function fetchLatestUserUnreadMessages(): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data: { user } } = await supabase.auth.getUser()

      if (!user) { throw new Error('User not found') }
      const userId = user.id

      const { data: participantData, error: participantError } = await supabase
        .from('Conversation_Participants')
        .select('*')
        .eq('user_id', userId)
        .eq('read', false)

      if (participantError) { throw participantError }

      const unreadMessagesList: Message[] = []
      for (const participant of participantData) {
        const { conversation_id } = participant

        const { data: latestMessageData, error: latestMessageError } = await supabase
          .from('Messages')
          .select('*')
          .eq('conversation_id', conversation_id)
          .order('created_at', { ascending: false })
          .limit(1)

        if (latestMessageError) { throw latestMessageError }

        if (latestMessageData && latestMessageData.length > 0) {
          unreadMessagesList.push(latestMessageData[0] as Message)
        }
      }

      unreadMessages.value = unreadMessagesList.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())

      subscribeToUnreadMessageChanges(userId)
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching latest unread messages:', e)
    }
    finally {
      loading.value = false
    }
  }

  function subscribeToConversationChanges(userId: string): void {
    supabase
      .channel(`public:Conversation_Participants:user_id=eq.${userId}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'Conversation_Participants', filter: `user_id=eq.${userId}` },
        () => {
          fetchConversations()
        },
      )
      .subscribe()

    useLogger().log('Subscribed to user conversations for real-time updates', '#realtime', '#subscribeToConversationChanges')
  }

  function subscribeToMessageChanges(userId: string, conversationId: string): void {
    const route = useRouter()
    supabase
      .channel(`public:Messages:conversation_id=eq.${conversationId}`)
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'Messages', filter: `conversation_id=eq.${conversationId}` },
        (payload) => {
          const newMessage = payload.new as Message
          if (messages.value[conversationId]) {
            messages.value[conversationId].push(newMessage)
            const conversation = conversations.value.find(c => c.id === conversationId)
            if (conversation) {
              conversation.updated_at = newMessage.created_at
              conversations.value.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
            }

            if (newMessage.sender_id !== userId && route.currentRoute.value.path !== `/app/messages/${conversationId}`) {
              useToast().toast({ title: 'You have a new message!', variant: 'default', duration: 4000 })
            }

            // Update the unreadMessages array
            fetchLatestUserUnreadMessages()
          }
        },
      )
      .subscribe()

    useLogger().log('Subscribed to messages for real-time updates', '#realtime', '#subscribeToMessageChanges')
  }

  function subscribeToUnreadMessageChanges(userId: string): void {
    supabase
      .channel(`public:Conversation_Participants:user_id=eq.${userId}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'Conversation_Participants', filter: `user_id=eq.${userId}` },
        () => {
          fetchLatestUserUnreadMessages()
        },
      )
      .subscribe()

    supabase
      .channel(`public:Messages`)
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'Messages' },
        () => {
          fetchLatestUserUnreadMessages()
        },
      )
      .subscribe()

    useLogger().log('Subscribed to unread messages for real-time updates', '#realtime', '#subscribeToUnreadMessageChanges')
  }

  function subscribeToOnlineStatus() {
    const channel = supabase.channel('online-users')

    const subscription = channel
      .on('presence', { event: 'sync' }, () => {
        const newState = channel.presenceState()
        onlineUsers.value = new Set(Object.values(newState).flatMap(presence => presence.map((p: any) => p.user_id)))
      })
      .on('presence', { event: 'join' }, ({ newPresences }) => {
        newPresences.forEach((presence: any) => {
          onlineUsers.value.add(presence.user_id)
        })
      })
      .on('presence', { event: 'leave' }, ({ leftPresences }) => {
        leftPresences.forEach((presence: any) => {
          onlineUsers.value.delete(presence.user_id)
        })
      })
      .subscribe(async (status) => {
        if (status === 'SUBSCRIBED') {
          const { data: { user } } = await supabase.auth.getUser()
          if (user) {
            await channel.track({ user_id: user.id })
          }
        }
      })

    return { channel, unsubscribe: () => subscription.unsubscribe() }
  }

  function initializeOnlineStatus(): void {
    channelInstance = subscribeToOnlineStatus()
  }

  async function cleanupOnlineStatus(): Promise<void> {
    if (channelInstance) {
      const { data: { user } } = await supabase.auth.getUser()
      if (user) {
        await channelInstance.channel.untrack()
      }
      channelInstance.unsubscribe()
      channelInstance = null
    }
  }

  return {
    fetchConversations,
    fetchMessages,
    fetchConversationParticipants,
    fetchLatestUserUnreadMessages,
    conversations,
    messages,
    participants,
    unreadMessages,
    loading,
    error,
    hasUnreadMessages,
    getLatestMessage,
    onlineUsers,
    isUserOnline,
    initializeOnlineStatus,
    cleanupOnlineStatus,
  }
})

export type UserConversationsStore = ReturnType<typeof useUserConversationsStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserConversationsStore, import.meta.hot))
}
